











import UserService, { UserServiceS } from '@/modules/user/user.service';
import { Inject } from 'inversify-props';
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class DMSPage extends Vue {
    @Inject(UserServiceS) userService!: UserService;

    get queryParams() {
        const params = {
            fornovaId: this.userService.storeState.user!.currentHotelId,
        };

        return Object
            .entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
    }
}
